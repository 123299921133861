/**
 * The aspect ratio constant which indicates that the width (of whatever the
 * aspect ratio constant is used for) is smaller than the height.
 *
 * @type {Symbol}
 */
export const ASPECT_RATIO_NARROW = Symbol('ASPECT_RATIO_NARROW');

/**
 * The aspect ratio constant which indicates that the width (of whatever the
 * aspect ratio constant is used for) is larger than the height.
 *
 * @type {Symbol}
 */
export const ASPECT_RATIO_WIDE = Symbol('ASPECT_RATIO_WIDE');
