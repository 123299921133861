/**
 * Notifies interested parties that hangup procedure will start.
 */
export const BEFORE_HANGUP = 'conference.before_hangup';

/**
 * Notifies interested parties that desktop sharing enable/disable state is
 * changed.
 */
export const DESKTOP_SHARING_ENABLED_CHANGED
    = 'conference.desktop_sharing_enabled_changed';
